module.exports = {
  "name": "optitrack",
  "short_name": "Optitrack",
  "start_url": "/",
  "display": "standalone",
  "background_color": "#ffffff",
  "theme_color": "#000000",
  "icons": [
    {
      "src": "/opti.png",
      "sizes": "192x192",
      "type": "image/png"
    }
  ]
}

;